import {graphql} from 'gatsby';
import {useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';
import Layout from '../components/Layout';
import GiftVoucher from '../components/Page/gift-voucher/GiftCardForm';
import SEO from '../components/Seo';

const GiftVoucherPage = () => {
  const {language, t} = useI18next();

  return (
    <Layout headerStyle="default">
      <SEO
        title={t('seo_title')}
        description={t('seo_description', {returnObjects: true})}
        lang={language}
        price={500}
        // keywords={t('homepage_seo_keywords', {returnObjects: true})}
      />
      <GiftVoucher />
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {in: ["common", "voucher_gift", "seo_gift_vouchers"]}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default GiftVoucherPage;
